import React, { useState } from 'react';
import styled from 'styled-components';
import { FaPlus } from 'react-icons/fa';
import PropertyList from './QB Lists/PropertyList';
import { collection, query, where, getDocs, addDoc, getDoc, doc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from './Firebase Functions/firebase';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 1rem; /* Reduced padding for mobile */
  border-radius: 12px;
  width: 95%; /* Use more screen width on mobile */
  max-width: 600px; /* Cap modal width for larger screens */
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 768px) {
    padding: 0.75rem;
    border-radius: 8px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;

  h2 {
    margin: 0;
    font-size: 1.2rem;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
`;


const ScrollableTasksContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 1rem;
  padding: 0.5rem;

  @media (max-width: 768px) {
    max-height: 150px;
  }
`;




const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #666;
  cursor: pointer;

  &:hover {
    color: #000;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;


const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
`;

const SuggestionsContainer = styled.div`
  position: relative; /* Provides a context for the absolute positioning */
  z-index: 1040; /* Lower than SuggestionsList to allow overlap */
`;


const SuggestionsList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1050; /* Ensure it's above other elements */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;


const SuggestionItem = styled.li`
  padding: 8px 12px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Button = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.75rem;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }
`;


const AddButton = styled.button`
  padding: 0.75rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &:hover {
    background-color: #45a049;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  background: white;
  padding-top: 0.5rem;

  button {
    padding: 0.75rem 1.5rem;
  }
`;

const AddTaskModal = ({ isOpen, onClose, user, onTaskAdded }) => {
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [taskNote, setTaskNote] = useState('');
  const [supervisorInput, setSupervisorInput] = useState('');
  const [supervisorSuggestions, setSupervisorSuggestions] = useState([]);
  const [selectedSupervisor, setSelectedSupervisor] = useState(null);
  const [completionWeek, setCompletionWeek] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchSupervisors = async (searchTerm) => {
    if (!searchTerm.trim()) {
      setSupervisorSuggestions([]);
      return;
    }
    try {
      const q = query(collection(db, 'Users'), where('type', '==', 'supervisor'));
      const snapshot = await getDocs(q);

      const supervisors = snapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((supervisor) =>
          supervisor.first_name.toLowerCase().includes(searchTerm.toLowerCase())
        );

      setSupervisorSuggestions(supervisors);
    } catch (error) {
      console.error('Error fetching supervisors:', error);
    }
  };

  const handleSupervisorInputChange = (e) => {
    const searchTerm = e.target.value;
    setSupervisorInput(searchTerm);
    fetchSupervisors(searchTerm);
  };

  const handleSupervisorSelect = (supervisor) => {
    setSelectedSupervisor(supervisor);
    setSupervisorInput(`${supervisor.first_name} ${supervisor.last_name}`);
    setSupervisorSuggestions([]);
  };

  const handleAddStandaloneTask = () => {
    if (!taskNote.trim()) return;
    setTasks((prev) => [...prev, { type: 'text', note: taskNote.trim(), completed: false }]);
    setTaskNote('');
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const newImageTasks = files.map((file) => ({
      type: 'image',
      image: { file, preview: URL.createObjectURL(file) },
      note: '',
      completed: false,
    }));
    setTasks((prev) => [...prev, ...newImageTasks]);
  };

  const handleNoteChange = (index, value) => {
    setTasks((prev) =>
      prev.map((task, i) => (i === index ? { ...task, note: value } : task))
    );
  };

  const handleRemoveTask = (index) => {
    setTasks((prevTasks) => prevTasks.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedProperty || !selectedSupervisor || !completionWeek) {
      alert('Please fill in all required fields.');
      return;
    }

    setLoading(true);

    try {
      const userDoc = await getDoc(doc(db, 'Users', user.uid));
      let userFullName = 'Unknown';
      if (userDoc.exists()) {
        const userData = userDoc.data();
        userFullName = `${userData.first_name || ''} ${userData.last_name || ''}`.trim();
      }

      const storage = getStorage();
      const uploadedImageTasks = await Promise.all(
        tasks
          .filter((task) => task.type === 'image' && task.image.file)
          .map(async (task, index) => {
            const imageRef = ref(
              storage,
              `tasks/${user.uid}/${Date.now()}_${index}_${task.image.file.name}`
            );
            await uploadBytes(imageRef, task.image.file);
            const url = await getDownloadURL(imageRef);
            return {
              type: 'image',
              url,
              note: task.note,
              completed: false,
            };
          })
      );

      const finalTasks = [
        ...uploadedImageTasks,
        ...tasks.filter((task) => task.type === 'text'),
      ];

      const taskData = {
        propertyName: selectedProperty?.['Property Name'],
        propertyId: selectedProperty?.['Property Name'],
        supervisorId: selectedSupervisor.id,
        supervisorName: `${selectedSupervisor.first_name} ${selectedSupervisor.last_name}`,
        supervisorEmail: selectedSupervisor.email,
        tasks: finalTasks,
        completionWeek,
        createdAt: new Date(),
        managerId: user.uid,
        assignedBy: {
          uid: user.uid,
          name: userFullName,
          email: user.email,
        },
        completed: false,
      };

      await addDoc(collection(db, 'tasks'), taskData);

      setSelectedProperty(null);
      setSupervisorInput('');
      setSelectedSupervisor(null);
      setCompletionWeek('');
      setTasks([]);
      onTaskAdded();
      onClose();
    } catch (error) {
      console.error('Error saving task:', error);
      alert('Failed to save the task.');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        {/* Sticky Header */}
        <div style={{ position: "sticky", top: 0, background: "#fff", zIndex: 10 }}>
          <CloseButton onClick={onClose}>&times;</CloseButton>
          <h2 style={{ marginBottom: "1rem", textAlign: "center", fontSize: "1.5rem" }}>
            Create New Punch List
          </h2>
        </div>
  
        {/* Form Content */}
        <Form onSubmit={handleSubmit}>
          {/* Property Selection */}
          <div>
            <label style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "0.5rem" }}>
              Property
            </label>
            <SuggestionsContainer>
              <PropertyList onSelect={(property) => setSelectedProperty(property)} />
            </SuggestionsContainer>
          </div>
  
          {/* Supervisor Selection */}
          <div style={{ position: "relative" }}>
            <label style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "0.5rem" }}>
              Assign to Supervisor
            </label>
            <Input
              type="text"
              value={supervisorInput}
              onChange={handleSupervisorInputChange}
              placeholder="Search for a supervisor"
              required
            />
            {supervisorSuggestions.length > 0 && (
              <SuggestionsList>
                {supervisorSuggestions.map((supervisor) => (
                  <SuggestionItem
                    key={supervisor.id}
                    onClick={() => handleSupervisorSelect(supervisor)}
                  >
                    {supervisor.first_name} {supervisor.last_name}
                  </SuggestionItem>
                ))}
              </SuggestionsList>
            )}
          </div>
  
          {/* Due Date Input */}
          <div>
            <label style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "0.5rem" }}>
              Due Date
            </label>
            <Input
              type="date"
              value={completionWeek}
              onChange={(e) => setCompletionWeek(e.target.value)}
              required
            />
          </div>
  
          {/* Tasks Without Images */}
          <div>
            <h3 style={{ fontSize: "1.1rem", marginBottom: "0.5rem" }}>
              Add Tasks Without Image
            </h3>
            <div style={{ display: "flex", gap: "0.5rem", marginBottom: "0.5rem" }}>
              <Input
                type="text"
                value={taskNote}
                onChange={(e) => setTaskNote(e.target.value)}
                placeholder="Add Punch List Item Without Image"
              />
              <AddButton type="button" onClick={handleAddStandaloneTask}>
                <FaPlus /> Add Item
              </AddButton>
            </div>
  
            {/* Scrollable Task List */}
            <ScrollableTasksContainer>
              {tasks.map((task, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0.5rem",
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  {task.type === "text" ? (
                    <span>{task.note}</span>
                  ) : (
                    <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                      <img
                        src={task.image?.preview || ""}
                        alt={`Task ${index}`}
                        style={{
                          width: "80px",
                          height: "80px",
                          objectFit: "cover",
                          borderRadius: "4px",
                        }}
                      />
                      <Input
                        type="text"
                        placeholder="Add a note for this image"
                        value={task.note || ""}
                        onChange={(e) => handleNoteChange(index, e.target.value)}
                        required
                      />
                    </div>
                  )}
                  <button
                    onClick={() => handleRemoveTask(index)}
                    style={{
                      background: "red",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      padding: "0.5rem 1rem",
                    }}
                  >
                    Remove
                  </button>
                </div>
              ))}
            </ScrollableTasksContainer>
          </div>
  
          {/* Tasks With Images */}
          <div>
            <h3 style={{ fontSize: "1.1rem", marginBottom: "0.5rem" }}>
              Add Tasks With Images
            </h3>
            <Input
              id="images"
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageUpload}
            />
          </div>
  
          {/* Sticky Footer */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
              position: "sticky",
              bottom: 0,
              background: "#fff",
              paddingTop: "0.5rem",
              zIndex: 10,
            }}
          >
            <Button type="submit" disabled={loading}>
              {loading ? "Saving..." : "Save Punch List"}
            </Button>
          </div>
        </Form>
      </ModalContent>
    </ModalOverlay>
  );
  
  
};

export default AddTaskModal;
