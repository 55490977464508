import React, { useState } from 'react';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../Firebase Functions/firebase';

const ImageUpload = () => {
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    if (files.length + images.length > 5) {
      alert('You can only upload up to 5 images in total.');
      return;
    }
    setImages((prev) => [...prev, ...files]);
  };

  const handleUpload = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      alert('You must be logged in to upload images.');
      return;
    }

    if (images.length === 0) {
      alert('Please select at least one image to upload.');
      return;
    }

    setUploading(true);

    const storage = getStorage();

    try {
      const uploadPromises = images.map(async (image) => {
        const storageRef = ref(storage, `images/${user.uid}/${Date.now()}_${image.name}`);
        await uploadBytes(storageRef, image);
        const downloadURL = await getDownloadURL(storageRef);

        const metadata = {
          name: image.name,
          size: image.size,
          type: image.type,
          downloadURL,
          uploadedAt: serverTimestamp(),
          userId: user.uid,
        };

        await addDoc(collection(db, 'PropertyImages'), metadata);
      });

      await Promise.all(uploadPromises);
      alert('All images uploaded successfully!');
      setImages([]);
    } catch (error) {
      console.error('Error uploading images:', error);
      alert('An error occurred during the upload. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  const removeImage = (index) => {
    setImages((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Upload Images</h2>
      <div style={styles.inputContainer}>
        <label style={styles.fileInputLabel}>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageChange}
            style={styles.fileInput}
          />
          Choose Images
        </label>
      </div>
      {images.length > 0 && (
        <div style={styles.previewContainer}>
          {images.map((image, index) => (
            <div key={index} style={styles.imageWrapper}>
              <img
                src={URL.createObjectURL(image)}
                alt={`Preview ${index + 1}`}
                style={styles.previewImage}
              />
              <button onClick={() => removeImage(index)} style={styles.removeButton}>
                Remove
              </button>
            </div>
          ))}
        </div>
      )}
      <button
        onClick={handleUpload}
        disabled={uploading || images.length === 0}
        style={{
          ...styles.uploadButton,
          backgroundColor: uploading ? '#ccc' : '#4CAF50',
          cursor: uploading ? 'not-allowed' : 'pointer',
        }}
      >
        {uploading ? 'Uploading...' : 'Upload'}
      </button>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    textAlign: 'center',
    backgroundColor: '#f9f9f9',
    borderRadius: '10px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#2c3e50',
  },
  inputContainer: {
    marginBottom: '20px',
  },
  fileInputLabel: {
    display: 'inline-block',
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  fileInput: {
    display: 'none',
  },
  previewContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
    gap: '10px',
    marginBottom: '20px',
  },
  imageWrapper: {
    position: 'relative',
    textAlign: 'center',
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
  },
  previewImage: {
    width: '100%',
    height: '120px',
    objectFit: 'cover',
  },
  removeButton: {
    position: 'absolute',
    bottom: '5px',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '5px 10px',
    backgroundColor: '#e74c3c',
    color: 'white',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '12px',
  },
  uploadButton: {
    marginTop: '20px',
    padding: '10px 20px',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    fontWeight: 'bold',
    fontSize: '16px',
  },
};

export default ImageUpload;
