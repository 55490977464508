import React, { useState } from 'react';

const BidProposalDemo = () => {
  const [proposal, setProposal] = useState({
    title: '',
    description: '',
    amount: '',
    attachments: [],
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProposal((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setProposal((prev) => ({
      ...prev,
      attachments: [...prev.attachments, ...files],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    console.log('Bid Proposal Submitted:', proposal);
    // Here you can integrate the API call to save the bid proposal
  };

  const resetForm = () => {
    setProposal({
      title: '',
      description: '',
      amount: '',
      attachments: [],
    });
    setSubmitted(false);
  };

  return (
    <div style={{ maxWidth: '600px', margin: 'auto', padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h2>Bid Proposal Submission</h2>
      {submitted ? (
        <div style={{ textAlign: 'center' }}>
          <h3>Proposal Submitted Successfully!</h3>
          <button onClick={resetForm} style={{ padding: '10px 20px', cursor: 'pointer' }}>
            Submit Another Proposal
          </button>
        </div>
      ) : (
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <label>
            <strong>Title:</strong>
            <input
              type="text"
              name="title"
              value={proposal.title}
              onChange={handleChange}
              required
              style={{ width: '100%', padding: '8px', marginTop: '5px' }}
            />
          </label>

          <label>
            <strong>Description:</strong>
            <textarea
              name="description"
              value={proposal.description}
              onChange={handleChange}
              required
              rows="4"
              style={{ width: '100%', padding: '8px', marginTop: '5px' }}
            ></textarea>
          </label>

          <label>
            <strong>Bid Amount:</strong>
            <input
              type="number"
              name="amount"
              value={proposal.amount}
              onChange={handleChange}
              required
              style={{ width: '100%', padding: '8px', marginTop: '5px' }}
            />
          </label>

          <label>
            <strong>Attachments:</strong>
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              style={{ marginTop: '5px' }}
            />
            <small>Attach up to 5 files</small>
          </label>

          <button type="submit" style={{ padding: '10px 20px', cursor: 'pointer', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '4px' }}>
            Submit Proposal
          </button>
        </form>
      )}
    </div>
  );
};

export default BidProposalDemo;
