import React, { useState, useEffect, useContext, useRef } from 'react';
import { db } from '../Firebase Functions/firebase';
import { collection, getDocs, doc, updateDoc, query, where, getDoc } from 'firebase/firestore';
import { AuthContext } from '../hooks/AuthProvider';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  text-align: center;
  color: #296b1a;
`;

const SectionTitle = styled.h3`
  margin-top: 30px;
  color: #333;
`;

const FormGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
`;

const Label = styled.label`
  font-size: 1rem;
  font-weight: bold;
  color: #333;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: #296b1a;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: #217014;
  }
`;

const SuggestionsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  background-color: white;
  position: absolute;
  z-index: 10;
  max-height: 150px;
  overflow-y: auto;
  width: 100%; /* Match input box width */
  top: calc(100% + 4px); /* Position just below the input box */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;


const AssignedVehiclesList = styled.div`
  margin-top: 20px;
`;

const AssignedVehicleItem = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
`;

const OilChangeList = styled.div`
  margin-top: 20px;
`;

const OilChangeItem = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
`;

const DefectsList = styled.ul`
  list-style: disc inside;
  padding-left: 1rem;
  margin: 0.5rem 0;
  color: #333;
  font-size: 0.95rem;
`;

const DefectItem = styled.li`
  margin-bottom: 0.5rem;
  line-height: 1.4;
`;

const VehicleDetails = styled.div`
  margin-top: 0.5rem;
`;

const DefectsSection = styled.div`
  margin-top: 1rem;
`;

const VehicleMaintenance = () => {
  const [vehicles, setVehicles] = useState([]);
  const [assignedVehicles, setAssignedVehicles] = useState([]);
  const [vehiclesNeedingOilChange, setVehiclesNeedingOilChange] = useState([]);
  const [vehicleDefects, setVehicleDefects] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const { currentUser } = useContext(AuthContext);
  const searchRef = useRef(null);

  // Fetch all vehicles
  useEffect(() => {
    const fetchTrucks = async () => {
      try {
        const truckCollection = collection(db, 'Trucks');
        const truckSnapshot = await getDocs(truckCollection);
        const truckList = truckSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setVehicles(truckList);
        filterVehiclesNeedingOilChange(truckList); // Check for vehicles needing oil change
      } catch (error) {
        console.error('Error fetching trucks:', error);
      }
    };

    fetchTrucks();
  }, []);

  // Fetch assigned vehicles and defects
  useEffect(() => {
    if (!currentUser) return;

    const fetchAssignedVehicles = async () => {
      try {
        const trucksQuery = query(
          collection(db, 'Trucks'),
          where('assignedTo', '==', currentUser.email)
        );
        const snapshot = await getDocs(trucksQuery);
        const assigned = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAssignedVehicles(assigned);

        fetchDefects(assigned);
        filterVehiclesNeedingOilChange(assigned);
      } catch (error) {
        console.error('Error fetching assigned vehicles:', error);
      }
    };

    fetchAssignedVehicles();
  }, [currentUser]);

  const fetchDefects = async (assignedVehicles) => {
    try {
      const defectsData = {};
  
      for (const vehicle of assignedVehicles) {
        const defectsQuery = query(
          collection(db, "vehicleInspections"),
          where("truckId", "==", vehicle.id)
        );
        const defectsSnapshot = await getDocs(defectsQuery);
  
        if (!defectsSnapshot.empty) {
          const latestInspection = defectsSnapshot.docs[0].data(); // Assuming latest inspection
          const checklist = latestInspection.checklist || {};
  
          // Keep only items marked as true
          defectsData[vehicle.id] = Object.keys(checklist).filter((key) => checklist[key]);
        } else {
          defectsData[vehicle.id] = []; // No defects
        }
      }
  
      setVehicleDefects(defectsData);
    } catch (error) {
      console.error("Error fetching defects:", error);
    }
  };
  
  
  

  const filterVehiclesNeedingOilChange = (vehiclesList) => {
    const needingOilChange = vehiclesList.filter((vehicle) => {
      const mileage = parseFloat(vehicle.mileage || 0);
      const lastOilChangeMileage = parseFloat(vehicle.lastOilChange || 0); // Default to 0 if missing
  
      // Check if mileage exceeds 7,000 miles from the last recorded oil change
      return mileage - lastOilChangeMileage >= 7000;
    });
  
    setVehiclesNeedingOilChange(needingOilChange);
  };
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    const filtered = vehicles.filter(
      (vehicle) =>
        vehicle.vehicleName?.toLowerCase().includes(e.target.value.toLowerCase()) &&
        !vehicle.assignedTo
    );
    setFilteredVehicles(filtered);
  };

  const assignVehicleToUser = async () => {
    if (!selectedVehicle) {
      alert('Please select a vehicle.');
      return;
    }
  
    try {
      const vehicleRef = doc(db, 'Trucks', selectedVehicle.id);
  
      // Check if the vehicle already has `lastOilChange` set
      const vehicleDoc = await getDoc(vehicleRef);
      if (!vehicleDoc.exists() || vehicleDoc.data().lastOilChange === undefined) {
        // Add `lastOilChange` field with default value `0`
        await updateDoc(vehicleRef, { lastOilChange: 0 });
      }
  
      // Assign the vehicle to the current user
      await updateDoc(vehicleRef, { assignedTo: currentUser.email });
      alert(`Vehicle ${selectedVehicle.vehicleName} assigned to you.`);
      setAssignedVehicles((prev) => [...prev, { ...selectedVehicle, assignedTo: currentUser.email }]);
      setSearchTerm('');
      setSelectedVehicle(null);
    } catch (error) {
      console.error('Error assigning vehicle:', error);
      alert('Failed to assign vehicle.');
    }
  };
  

  const updateLastOilChange = async (vehicle) => {
    const confirmChange = window.confirm(
      `Are you sure you want to mark ${vehicle.vehicleName} as serviced?`
    );
    if (!confirmChange) return;

    try {
      const vehicleRef = doc(db, 'Trucks', vehicle.id);
      await updateDoc(vehicleRef, { lastOilChange: vehicle.mileage });
      alert(`${vehicle.vehicleName} has been serviced.`);
      setVehiclesNeedingOilChange((prev) =>
        prev.filter((v) => v.id !== vehicle.id)
      );
    } catch (error) {
      console.error('Error updating oil change:', error);
      alert('Failed to update oil change status.');
    }
  };

  return (
    <Container>
      <Title>Your Assigned Vehicles</Title>

      <AssignedVehiclesList>
        {assignedVehicles.length === 0 ? (
          <p>You have no vehicles assigned.</p>
        ) : (
          assignedVehicles.map((vehicle) => (
            <AssignedVehicleItem key={vehicle.id}>
              <p>
                <strong>Truck Number:</strong> {vehicle.vehicleName}
              </p>
              <VehicleDetails>
                <p>Mileage: {vehicle.mileage}</p>
                {vehicleDefects[vehicle.id] && vehicleDefects[vehicle.id].length > 0 && (
                  <DefectsSection>
                    <p><strong>Defects:</strong></p>
                    <DefectsList>
                      {vehicleDefects[vehicle.id].map((defect, index) => (
                        <DefectItem key={index}>{defect}</DefectItem>
                      ))}
                    </DefectsList>
                  </DefectsSection>
                )}
              </VehicleDetails>
            </AssignedVehicleItem>
          ))
        )}
      </AssignedVehiclesList>

      <SectionTitle>Assign a Vehicle</SectionTitle>
      <FormGroup style={{ position: 'relative' }}>
  <Label>Search for a Vehicle</Label>
  <Input
    type="text"
    placeholder="Type vehicle name or ID"
    value={searchTerm}
    onChange={(e) => {
      const value = e.target.value;
      setSearchTerm(value);

      // Filter vehicles dynamically
      if (value.trim() === '') {
        setFilteredVehicles([]); // Clear suggestions when input is empty
      } else {
        const filtered = vehicles.filter(
          (vehicle) =>
            vehicle.vehicleName?.toLowerCase().includes(value.toLowerCase()) &&
            !vehicle.assignedTo // Only show unassigned vehicles
        );
        setFilteredVehicles(filtered);
      }
    }}
    onFocus={() => setShowSuggestions(true)} // Show suggestions when input is focused
    onBlur={() => setTimeout(() => setShowSuggestions(false), 100)} // Delay to allow clicking a suggestion
  />
  {showSuggestions && filteredVehicles.length > 0 && (
    <SuggestionsList>
      {filteredVehicles.map((vehicle) => (
        <SuggestionItem
          key={vehicle.id}
          onClick={() => {
            setSelectedVehicle(vehicle);
            setSearchTerm(vehicle.vehicleName || `Truck ${vehicle.id}`);
            setShowSuggestions(false); // Close suggestions after selecting
          }}
        >
          {vehicle.vehicleName || `Truck ${vehicle.id}`}
        </SuggestionItem>
      ))}
    </SuggestionsList>
  )}
</FormGroup>



      {selectedVehicle && (
        <Button onClick={assignVehicleToUser}>Assign Selected Vehicle</Button>
      )}

<SectionTitle>Vehicles Needing Oil Change</SectionTitle>
      <OilChangeList>
        {vehiclesNeedingOilChange.length === 0 ? (
          <p>No vehicles need an oil change at the moment.</p>
        ) : (
          vehiclesNeedingOilChange.map((vehicle) => (
            <OilChangeItem key={vehicle.id}>
              <p>
                <strong>{vehicle.vehicleName}</strong>
              </p>
              <p>Mileage: {vehicle.mileage}</p>
              <p>Last Oil Change Mileage: {vehicle.lastOilChange}</p>
              <Button onClick={() => updateLastOilChange(vehicle)}>Mark Serviced</Button>
            </OilChangeItem>
          ))
        )}
      </OilChangeList>
    </Container>
  );
};

export default VehicleMaintenance;