import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth'
import { useParams , useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import PropertyList from '../QB Lists/PropertyList';
import { format } from 'date-fns';
import { doc, setDoc, collection, updateDoc, getDoc, serverTimestamp, query, where, getDocs} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from '../Firebase Functions/firebase';
import AMEmailSender from '../Email Functions/Email Sender/AMEmailSender';
import { useTranslation } from 'react-i18next';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const Input = styled.input`
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ServiceCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  @media (max-width: 768px) {
    margin-bottom: 0.75rem;
  }
`;

const CheckboxLabel = styled.label`
  margin-left: 0.5rem;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ImagePreview = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Button = styled.button`
  background-color: #27ae60;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 0.75rem;
    font-size: 16px;
    width: 100%;
  }
`;

const RemoveButton = styled.button.attrs({ type: 'button' })`
  background-color: #27ae60;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 0.5rem;
    margin-top: 0.5rem;
    width: 100%;
    margin-left: 0;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  width: 90%;
  max-width: 500px;
`;

const services = [
  'Turf Mow', 'String Trim', 'Edging', 'Blow/Cleanup', 'Irrigation Monitoring and Control',
  'Mulch Install', 'Annuals Install', 'Tree Pruning', 'Palm Pruning', 'Weed Management', 'Crack and Crevice', 'Litter/Debris Removal',
  'Remediation', 'Seasonal Color Watering', 'Shrub Pruning', 'Pond Maintenance', 'Other'
];

const JobForm = ({ initialData }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id: jobIdFromParams } = useParams();
  const [jobId, setJobId] = useState(jobIdFromParams || null);
  const [formData, setFormData] = useState({
    supervisorName: '',
    property: '',
    serviceTypes: [],
    customServiceDescription: '',
    date: format(new Date(), 'yyyy-MM-dd'),
    notes: '',
    image: null,
    secondImage: null,
    thirdImage: null,
    fourthImage: null,
    fifthImage: null,
    customPropertyName: '',
    truckNumber: '',  // New field for Truck Number
    truckDriver: '',
    useMultipleUploads: false   // New field for Truck Driver
  });
  const [error, setError] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userType, setUserType] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isEmailSenderOpen, setEmailSenderOpen] = useState(false);
  const [submittedJobData, setSubmittedJobData] = useState(null);
  const isEditing = !!initialData;

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    } else {
      setFormData(prevState => ({
        ...prevState,
        date: format(new Date(), 'yyyy-MM-dd')
      }));
    }
  }, [initialData]);

  useEffect(() => {
    const fetchUserData = async () => {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      
      if (currentUser) {
        const userEmail = currentUser.email;
        setUserEmail(userEmail); // This sets the createdBy field
        
        try {
          // Query Users collection where email matches current user's email
          const usersRef = collection(db, 'Users');
          const q = query(usersRef, where("email", "==", userEmail));
          const querySnapshot = await getDocs(q);
          
          if (!querySnapshot.empty) {
            // Get the first matching document
            const userData = querySnapshot.docs[0].data();
            const userType = userData.type;
            console.log('User type found:', userType); // Debug log
            
            // Set both supervisorName and userType in formData
            const fullName = `${userData.first_name} ${userData.last_name}`.trim();
            setFormData(prevState => ({
              ...prevState,
              supervisorName: fullName,
              userType: userType // This will eventually go to the Services collection
            }));
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };
  
    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked :
              type === 'file' ? files[0] :
              value
    }));
  };

  const handleServiceChange = (e) => {
    const { value, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      serviceTypes: checked
        ? [...prevState.serviceTypes, value]
        : prevState.serviceTypes.filter(service => service !== value)
    }));
  };

  const handleRemoveImage = (imageName) => {
    setFormData(prevState => ({
      ...prevState,
      [imageName]: null
    }));
  };
  

  const handleSingleImageUpload = (e, imageName) => {
    const file = e.target.files[0]; // Get the selected file
    if (!file) return;
  
    setFormData(prevState => ({
      ...prevState,
      [imageName]: file // Assign the selected file to the specific image field
    }));
  };

  const handleMultipleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const imageFields = ['image', 'secondImage', 'thirdImage', 'fourthImage', 'fifthImage'];
    
    const newFormData = { ...formData };
    files.forEach((file, index) => {
      if (index < imageFields.length) {
        newFormData[imageFields[index]] = file;
      }
    });
  
    setFormData(newFormData);
  };
  
  
  

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to an array
    const imageFields = ['image', 'secondImage', 'thirdImage', 'fourthImage', 'fifthImage'];
    
    // Get the next available fields in the formData for images
    const availableFields = imageFields.filter(field => !formData[field]);
    
    if (files.length > availableFields.length) {
      alert(`You can only upload ${availableFields.length} more images.`);
      return;
    }
  
    const newFormData = { ...formData };
    files.forEach((file, index) => {
      if (index < availableFields.length) {
        newFormData[availableFields[index]] = file; // Assign to the next available field
      }
    });
  
    setFormData(newFormData);
  };
  

const handleSubmit = async (e) => {
  e.preventDefault();
  setError('');
  setSuccessMessage('');

  if (!formData.property) {
    setIsModalOpen(true);
    return;
}

  try {
      // Prepare all the data to be passed to AMEmailSender
      const jobDataForEmail = {
          supervisorName: formData.supervisorName,
          property: formData.property,
          customPropertyName: formData.customPropertyName,
          serviceTypes: formData.serviceTypes,
          customServiceDescription: formData.customServiceDescription,
          notes: formData.notes,
          date: formData.date,
          image: formData.image,
          secondImage: formData.secondImage,
          thirdImage: formData.thirdImage,
          fourthImage: formData.fourthImage,
          fifthImage: formData.fifthImage,
          truckNumber: formData.truckNumber,
          truckDriver: formData.truckDriver,
          isEditing,
          jobId,
          createdBy: userEmail,
          userType: formData.userType
      };

      setSubmittedJobData(jobDataForEmail);
      setEmailSenderOpen(true);
      

  } catch (error) {
      console.error('Error preparing data:', error);
      setError('An error occurred while preparing the form data. Please try again.');
  }
};



  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="supervisorName"
          value={formData.supervisorName}
          onChange={handleChange}
          placeholder={t('Supervisor Name')}
          required
        />
        <Input
          type="text"
          name="truckNumber"
          value={formData.truckNumber}
          onChange={handleChange}
          placeholder={t('Truck Number')}/>
           <Input
          type="text"
          name="truckDriver"
          value={formData.truckDriver}
          onChange={handleChange}
          placeholder={t('Truck Driver')}/>
         <Input
          type="date"
          name="date"
          value={formData.date}
          onChange={handleChange}
          required
        />
        <PropertyList 
          onSelect={(property) => 
            setFormData(prev => ({ ...prev, property: property["Property Name"] }))
          }
          required
        />
        <Input
          type="text"
          name="customPropertyName"
          value={formData.customPropertyName}
          onChange={handleChange}
          placeholder={t('Custom Property Name')}
        />
        {services.map(service => (
          <ServiceCheckbox key={service}>
            <input
              type="checkbox"
              id={service}
              name="serviceTypes"
              value={service}
              checked={formData.serviceTypes.includes(service)}
              onChange={handleServiceChange}
            />
            <CheckboxLabel htmlFor={service}>{t(service)}</CheckboxLabel>
          </ServiceCheckbox>
        ))}
        {formData.serviceTypes.includes('Other') && (
          <Input
            type="text"
            name="customServiceDescription"
            value={formData.customServiceDescription}
            onChange={handleChange}
            placeholder={t('If Services Not Listed, Add Them Here')}
            required
          />
        )}
        <Input
          type="text"
          name="notes"
          value={formData.notes}
          onChange={handleChange}
          placeholder={t('Additional Notes')}
        />
        <div>
  <label>
    <input
      type="checkbox"
      checked={formData.useMultipleUploads}
      onChange={() =>
        setFormData(prev => ({ ...prev, useMultipleUploads: !prev.useMultipleUploads }))
      }
    />
    Upload Multiple Images at Once
  </label>
</div>

{formData.useMultipleUploads ? (
  // Single input for multiple images
  <div>
    <label htmlFor="multiUpload">Choose Multiple Files</label>
    <Input
      id="multiUpload"
      type="file"
      multiple
      accept="image/*"
      onChange={handleMultipleImageUpload}
    />
    {['image', 'secondImage', 'thirdImage', 'fourthImage', 'fifthImage'].map(imageName => (
      formData[imageName] && (
        <ImagePreview key={imageName}>
          <img
            src={
              formData[imageName] instanceof File
                ? URL.createObjectURL(formData[imageName])
                : formData[imageName]
            }
            alt={`Preview of ${imageName}`}
            height="50"
          />
          <RemoveButton onClick={() => handleRemoveImage(imageName)}>Remove</RemoveButton>
        </ImagePreview>
      )
    ))}
  </div>
) : (
  // Separate inputs for each image
  ['image', 'secondImage', 'thirdImage', 'fourthImage', 'fifthImage'].map((imageName, index) => (
    <div key={imageName}>
      <Input
        id={imageName}
        type="file"
        accept="image/*"
        onChange={(e) => handleSingleImageUpload(e, imageName)}
      />
      {formData[imageName] && (
        <ImagePreview>
          <img
            src={
              formData[imageName] instanceof File
                ? URL.createObjectURL(formData[imageName])
                : formData[imageName]
            }
            alt={`Preview of ${imageName}`}
            height="50"
          />
          <RemoveButton onClick={() => handleRemoveImage(imageName)}>Remove</RemoveButton>
        </ImagePreview>
      )}
    </div>
  ))
)}



        
       
        
        <Button type="submit">{isEditing ? t('Update Job') : t('Submit Job')}</Button>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {successMessage && <div style={{ color: 'green', marginTop: '10px' }}>{successMessage}</div>}
      </Form>

      {isEmailSenderOpen && (
        <>
          <ModalOverlay onClick={() => setEmailSenderOpen(false)} />
          <ModalContent>
          <AMEmailSender
            jobId={jobId}
            job={submittedJobData}
            onClose={() => {
        setEmailSenderOpen(false);
        navigate('/supervisor-dashboard');
    }}
/>
          </ModalContent>
        </>
      )}
       {isModalOpen && (
  <>
    <ModalOverlay onClick={() => setIsModalOpen(false)} />
    <ModalContent>
      <p>Please select a property.</p>
      <Button onClick={() => setIsModalOpen(false)}>Close</Button>
    </ModalContent>
  </>
)}

    </>
  );
};

export default JobForm;
