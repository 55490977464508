import React, { useState, useEffect, useContext } from 'react';
import { doc, deleteDoc } from 'firebase/firestore';
import { collection, query, where, getDocs } from 'firebase/firestore';
import styled from 'styled-components';
import { db } from './Firebase Functions/firebase';
import { AuthContext } from './hooks/AuthProvider';
import CompactTaskViewer from './CompactTaskViewer';
import AddTaskModal from './AddTaskModal';

const TaskListContainer = styled.div`
  margin: 80px auto 2rem;
  width: 100%;
  max-width: 800px;
  padding: 0 1rem;
  min-height: calc(100vh - 160px);
`;

const TaskItem = styled.div`
  background: ${({ completed }) => (completed ? '#d4edda' : '#fff3cd')}; /* Green for completed, yellow for in-progress */
  border: ${({ completed }) => (completed ? '1px solid #c3e6cb' : '1px solid #ffeeba')};
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
`;

const ProgressText = styled.p`
  font-size: 0.9rem;
  color: ${({ completed }) => (completed ? '#080808' : '#080808')};
`;

const AddTaskButton = styled.button`
  background: #27ae60;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  margin-bottom: 1rem;

  &:hover {
    background: #219653;
  }
`;

const Modal = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  width: 80%;
  max-height: 80vh;
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: #fff;
  color: #333;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0.5rem;

  &:hover {
    background: #ccc;
  }
`;

const TaskList = () => {
  const { currentUser: user } = useContext(AuthContext);
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const q = query(collection(db, 'tasks'), where('managerId', '==', user.uid));
        const snapshot = await getDocs(q);
  
        const currentDate = new Date();
        const fetchedTasks = snapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((task) => {
            const dueDate = new Date(task.completionWeek);
            // Keep tasks within 30 days of the due date
            return !isNaN(dueDate) && (dueDate >= currentDate || (currentDate - dueDate) / (1000 * 60 * 60 * 24) <= 30);
          })
          .sort((a, b) => {
            const dueDateA = new Date(a.completionWeek);
            const dueDateB = new Date(b.completionWeek);
            return dueDateA - dueDateB;
          });
  
        setTasks(fetchedTasks);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };
  
    fetchTasks();
  }, [user]);

  const openModal = (task) => {
    setSelectedTask([task]); // Pass task as an array for CompactTaskViewer
  };

  const closeModal = () => {
    setSelectedTask(null);
  };
  
  const handleTaskAdded = () => {
    setIsAddTaskModalOpen(false);
  
    const fetchTasks = async () => {
      try {
        const q = query(collection(db, 'tasks'), where('managerId', '==', user.uid));
        const snapshot = await getDocs(q);
  
        const currentDate = new Date();
        const fetchedTasks = snapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((task) => {
            const dueDate = new Date(task.completionWeek);
            return !isNaN(dueDate) && (dueDate >= currentDate || (currentDate - dueDate) / (1000 * 60 * 60 * 24) <= 30);
          })
          .sort((a, b) => {
            const dueDateA = new Date(a.completionWeek);
            const dueDateB = new Date(b.completionWeek);
            return dueDateA - dueDateB;
          });
  
        setTasks(fetchedTasks);
      } catch (error) {
        console.error('Error refreshing tasks:', error);
      }
    };
  
    fetchTasks();
  };
  

const handleTaskDeleted = async (taskId) => {
  // Step 1: Optimistically update tasks and close the modal
  setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
  closeModal(); // Immediately close the modal

  try {
    // Step 2: Perform delete operation in Firestore
    const taskRef = doc(db, 'tasks', taskId);
    await deleteDoc(taskRef);
    console.log(`Task with ID ${taskId} deleted successfully.`);
  } catch (error) {
    console.error('Error deleting task:', error);

    // Step 3: Rollback in case of failure
    setTasks((prevTasks) => {
      // Reinsert the deleted task (rollback)
      const deletedTask = selectedTask[0];
      return [...prevTasks, deletedTask].sort((a, b) => {
        const dueDateA = new Date(a.completionWeek);
        const dueDateB = new Date(b.completionWeek);
        return dueDateA - dueDateB;
      });
    });
    alert('Failed to delete the task. Please try again.');
  }
};


  return (
    <TaskListContainer>
      {/* Add Task Button */}
      <AddTaskButton onClick={() => setIsAddTaskModalOpen(true)}>Create New Punch List</AddTaskButton>

      {/* Task List */}
      {tasks.map((task) => {
        // Calculate progress for subtasks
        const completedSubtasks = task.tasks?.filter((subtask) => subtask.completed).length || 0;
        const totalSubtasks = task.tasks?.length || 0;

        return (
          <TaskItem
            key={task.id}
            completed={completedSubtasks === totalSubtasks && totalSubtasks > 0}
            onClick={() => openModal(task)}
          >
            <p><strong>Property:</strong> {task.propertyName}</p>
            <p><strong>Completion Week:</strong> {task.completionWeek}</p>
            <p><strong>Supervisor:</strong> {task.supervisorName}</p>
            <ProgressText completed={completedSubtasks === totalSubtasks && totalSubtasks > 0}>
              {completedSubtasks}/{totalSubtasks} Tasks Completed
            </ProgressText>
          </TaskItem>
        );
      })}

      {/* Modal for Task Details */}
      <Modal isOpen={!!selectedTask}>
        <ModalContent>
          <CloseButton onClick={closeModal}>✖</CloseButton>
          {selectedTask && (
            <CompactTaskViewer
              tasks={selectedTask}
              onTaskDeleted={handleTaskDeleted}
              closeModal={closeModal}
            />
          )}
        </ModalContent>
      </Modal>


      {/* Add Task Modal */}
      <AddTaskModal
        isOpen={isAddTaskModalOpen}
        onClose={() => setIsAddTaskModalOpen(false)}
        user={user}
        onTaskAdded={handleTaskAdded}
      />
    </TaskListContainer>
  );
};

export default TaskList;
