import React, { useEffect, useState } from "react";
import { db } from "../components/Firebase Functions/firebase";
import { collection, getDocs } from "firebase/firestore";
import "./AdminTaskList.css"; // Updated CSS filename for clarity

const AdminTaskList = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "tasks"));
        const tasksData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTasks(tasksData);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTasks();
  }, []);

  if (loading) {
    return <p>Loading tasks...</p>;
  }

  return (
    <div className="admin-task-list">
      {tasks.map((task) => (
        <div key={task.id} className="admin-task-card">
          <h3>{task.propertyName}</h3>
          <p><strong>Supervisor:</strong> {task.supervisorName}</p>
          {task.tasks.map((t, index) => (
            <div key={index} className="admin-task-detail">
              <p><strong>Notes:</strong> {t.note}</p>
              {t.type === "image" && (
                <img
                  src={t.url}
                  alt={`Task ${index}`}
                  className="admin-task-image"
                />
              )}
              <p><strong>Completed:</strong> {t.completed ? "Yes" : "No"}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default AdminTaskList;
