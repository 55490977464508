import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../Firebase Functions/firebase';

const ImageGallery = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, 'PropertyImages'));
        const fetchedImages = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setImages(fetchedImages);
      } catch (error) {
        console.error('Error fetching images:', error);
        alert('Failed to load images. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  const handleDelete = async (imageId) => {
    try {
      // Delete the document from Firestore
      await deleteDoc(doc(db, 'PropertyImages', imageId));

      // Remove the image from the local state
      setImages((prevImages) => prevImages.filter((image) => image.id !== imageId));

      alert('Image deleted successfully!');
    } catch (error) {
      console.error('Error deleting image:', error);
      alert('Failed to delete the image. Please try again.');
    }
  };

  const handleDownload = async (image) => {
    const { downloadURL, name, id } = image;

    try {
      const response = await fetch(downloadURL);
      if (!response.ok) {
        throw new Error('Failed to download the image.');
      }

      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = name;
      link.click();
      window.URL.revokeObjectURL(link.href);

      // After successful download, delete the image
      handleDelete(id);
    } catch (error) {
      console.error('Download failed:', error);
      alert('Failed to download the image. Please try again.');
    }
  };

  if (loading) {
    return <p>Loading images...</p>;
  }

  if (images.length === 0) {
    return <p>No images found.</p>;
  }

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h2 style={{ color: '#2c3e50', marginBottom: '20px' }}>Image Gallery</h2>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
          gap: '20px',
          justifyContent: 'center',
        }}
      >
        {images.map((image) => (
          <div
            key={image.id}
            style={{
              border: '1px solid #ccc',
              borderRadius: '10px',
              padding: '10px',
              textAlign: 'center',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            <img
              src={image.downloadURL}
              alt={image.name}
              style={{
                width: '100%',
                height: '150px',
                objectFit: 'cover',
                borderRadius: '10px',
              }}
            />
            <p
              style={{
                margin: '10px 0',
                fontWeight: 'bold',
                wordBreak: 'break-word',
                color: '#34495e',
              }}
            >
              {image.name}
            </p>
            <button
              onClick={() => handleDownload(image)}
              style={{
                marginTop: '10px',
                padding: '8px 15px',
                backgroundColor: '#4CAF50',
                color: 'white',
                borderRadius: '5px',
                border: 'none',
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
            >
              Download & Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;
