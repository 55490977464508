import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { format, parseISO } from 'date-fns'; 
import React from 'react';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    backgroundColor: '#FAFAF7',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#4CAF50',
  },
  logoAndContact: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    fontWeight: 'bold',
  },
  logo: {
    width: 140,
    height: 60,
    marginRight: 10,
  },
  contactInfo: {
    fontSize: 9,
    color: '#37474F',
    fontWeight: 'bold',
    lineHeight: 1.4,
    textAlign: 'right',
  },
  websiteLink: {
    color: '#FF0000',
  },
  serviceReportBox: {
    backgroundColor: '#2F5E1E',
    padding: '10px 20px',
    borderRadius: 4,
    justifyContent: 'center',
    height: 60,
  },
  serviceReportText: {
    fontSize: 14,
    color: '#FFFFFF',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  detailsSection: {
    marginTop: 10,
    paddingLeft: 5,
  },
  detailText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#37474F',
  },
  contentGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    marginTop: 10,
  },
  gridItem: {
    width: '48%',
    height: 165,
    marginBottom: 15,
    backgroundColor: '#F5F5F5',
    padding: 0,
  },
  servicesSection: {
    padding: 10,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#1B5E20',
    marginBottom: 6,
  },
  text: {
    fontSize: 11,
    color: '#37474F',
    lineHeight: 1.6,
  },
  list: {
    paddingLeft: 10,
  },
  listItem: {
    fontSize: 11,
    marginBottom: 3,
    color: '#37474F',
    flexDirection: 'row',
    alignItems: 'center',
  },
  bulletPoint: {
    fontSize: 11,
    marginRight: 4,
    color: '#37474F',
  },
  notesSection: {
    width: '48%',
    backgroundColor: '#F5F5F5',
    padding: 10,
    marginBottom: 15,
  },
  notesTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#1B5E20',
    marginBottom: 6,
  },
  notesText: {
    fontSize: 11,
    color: '#37474F',
    lineHeight: 1.6,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

const JobPDF = ({ formData, base64Images }) => {
  console.log('JobPDF received base64Images:', base64Images?.length);
  const {
    property,
    date,
    services,
    customServiceDescription,
    imageUrls,
    notes,
  } = formData;

  const formattedDate = format(
    typeof date === 'string' ? parseISO(date) : new Date(date),
    'MM/dd/yyyy'
  );

  // Convert services to an array and replace "Other" with the custom description if present
  const serviceList = Array.isArray(services)
    ? services
    : services.split(',').map(service => service.trim());

  const resolvedServiceList = serviceList.map(service =>
    service === 'Other' && customServiceDescription ? customServiceDescription : service
  );

  const images = typeof imageUrls === 'object'
    ? Object.values(imageUrls)
        .filter(Boolean)
        .map(url => {
          if (url.includes('firebasestorage.googleapis.com')) {
            if (url.includes('alt=media')) {
              return url;
            }
            return `${url}?alt=media`;
          }
          return url;
        })
    : [];

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <View style={styles.logoAndContact}>
            <Image style={styles.logo} src="/rciLogo.png" />
            <View style={styles.contactInfo}>
              <Text style={styles.websiteLink}>www.RotoloConsultants.com</Text>
              <Text>Phone: (800) 641-2427</Text>
            </View>
          </View>
          <View style={styles.serviceReportBox}>
            <Text style={styles.serviceReportText}>SERVICE REPORT</Text>
          </View>
        </View>

        {/* Property Name and Date Section */}
        <View style={styles.detailsSection}>
          <Text style={styles.detailText}>Property Name: {property}</Text>
          <Text style={styles.detailText}>Service Date: {formattedDate}</Text>
        </View>

        {/* Content Grid */}
        <View style={styles.contentGrid}>
          {/* Services List */}
          <View style={styles.gridItem}>
            <View style={styles.servicesSection}>
              <Text style={styles.sectionTitle}>Completed Services</Text>
              <View style={styles.list}>
                {resolvedServiceList.map((service, index) => (
                  <View key={index} style={styles.listItem}>
                    <Text style={styles.bulletPoint}>•</Text>
                    <Text>{service}</Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
          {/* Notes Section */}
          <View style={styles.notesSection}>
            <Text style={styles.notesTitle}>Notes</Text>
            <Text style={styles.notesText}>
              {notes || 'No notes available'}
            </Text>
          </View>

          {/* Images */}
          {base64Images?.slice(0, 5).map((base64Image, index) => (
            <View key={index} style={styles.gridItem}>
              <Image src={base64Image} style={styles.image} />
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export { JobPDF };
