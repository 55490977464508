import React, { useState, useEffect, useRef, useContext } from 'react';
import { pdf } from '@react-pdf/renderer';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL, getBytes  } from 'firebase/storage';
import { db } from '../../../components/Firebase Functions/firebase';
import { AuthContext } from '../../hooks/AuthProvider';
import './jobliststyles.css';
import EmailSender from '../../Email Functions/Email Sender/EmailSender';
import EditJob from '../Edit Job/EditJob';
import Compressor from 'compressorjs';
import UpdateQuickBaseComponent from '../../Quickbase Functions/quickBaseUtils';
import { JobPDF } from '../../PDF Functions/PDFGenerator';

function JobList() {
    const navigate = useNavigate();
    const { currentUser: user, loading: authLoading } = useContext(AuthContext);
    const [jobsData, setJobsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pendingCount, setPendingCount] = useState(0);
    const [modalImage, setModalImage] = useState(null);
    const [selectedJobData, setSelectedJobData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [quickBaseLoading, setQuickBaseLoading] = useState(false);
    const imageCacheRef = useRef({});

    const isValidJobData = (jobData) =>
        jobData &&
        jobData.property &&
        jobData.date &&
        Array.isArray(jobData.services);

        const handleQuickBaseUpdate = async (jobData) => {
            if (!jobData || !jobData.id) {
                alert('Invalid job data.');
                return;
            }
        
            try {
                // Check if 'sentOut' is true in Firebase
                const jobRef = doc(db, 'Services', jobData.id);
                const jobSnapshot = await getDoc(jobRef);
                if (jobSnapshot.exists() && jobSnapshot.data().sentOut) {
                    alert('This job has already been sent to Quickbase!');
                    return;
                }
            } catch (error) {
                console.error('Error checking sentOut status:', error);
                alert('Failed to check job status. Please try again.');
                return;
            }
        
            setQuickBaseLoading(true); // Start loading
            try {
                // Step 1: Fetch and compress images
                const compressImage = (file) =>
                    new Promise((resolve, reject) => {
                        new Compressor(file, {
                            quality: 0.4,
                            maxWidth: 1024,
                            maxHeight: 1024,
                            convertSize: 200000,
                            success: resolve,
                            error: reject,
                        });
                    });
        
                const fetchImageUrlsAndConvert = async () => {
                    const storage = getStorage();
                    const base64Images = [];
        
                    if (!jobData.imageUrls) return base64Images;
                    const validUrls = Object.values(jobData.imageUrls).filter(Boolean);
        
                    for (const url of validUrls) {
                        try {
                            const pathMatch = url.match(/o\/(.+?)\?/);
                            if (!pathMatch) continue;
        
                            const path = decodeURIComponent(pathMatch[1]);
                            const imageRef = ref(storage, path);
                            const bytes = await getBytes(imageRef);
                            const compressedImage = await compressImage(new Blob([bytes], { type: 'image/jpeg' }));
        
                            const base64 = await new Promise((resolve) => {
                                const reader = new FileReader();
                                reader.onloadend = () => resolve(reader.result);
                                reader.readAsDataURL(compressedImage);
                            });
        
                            base64Images.push(base64);
                        } catch (error) {
                            console.error('Error converting image to base64:', error);
                        }
                    }
        
                    return base64Images;
                };
        
                const base64Images = await fetchImageUrlsAndConvert();
        
                // Step 2: Generate the PDF
                const pdfBlob = await pdf(
                    <JobPDF formData={jobData} base64Images={base64Images} />
                ).toBlob();
        
                // Step 3: Convert PDF to Base64
                const pdfBase64 = await new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result.split(',')[1]); // Get the Base64 string
                    reader.readAsDataURL(pdfBlob);
                });
        
                // Step 4: Push to QuickBase
                const response = await UpdateQuickBaseComponent(jobData.id, pdfBase64);
                console.log('QuickBase response:', response);
        
                alert('Successfully pushed data to QuickBase!');
            } catch (error) {
                console.error('Error pushing data to QuickBase:', error);
                alert('Failed to push data to QuickBase: ' + error.message);
            } finally {
                setQuickBaseLoading(false); // Stop loading
            }
        };
        
        

    useEffect(() => {
        const fetchJobs = async () => {
            if (!user?.email) return;

            try {
                const propertiesSnapshot = await getDocs(collection(db, 'Properties'));
                const propertiesData = {};

                // Collect property emails
                propertiesSnapshot.docs.forEach(doc => {
                    const data = doc.data();
                    const allEmails = [
                        ...(data['AM Email List'] || '').split(',').map(email => email.trim()),
                        ...(data['DM Email List'] || '').split(',').map(email => email.trim()),
                        ...(data['RM Email List'] || '').split(',').map(email => email.trim()),
                    ];
                    propertiesData[data['Property Name']] = allEmails;
                });

                const querySnapshot = await getDocs(collection(db, 'Services'));
                const jobsDataArray = [];
                let countPending = 0;

                for (const document of querySnapshot.docs) {
                    const jobData = document.data();

                    // Ensure the user has access to this property
                    const propertyEmails = propertiesData[jobData.property] || [];
                    if (!propertyEmails.includes(user.email)) continue;

                    // Check if the job has been sent to the customer
                    if (jobData.sentToCustomer === true) {
                        console.log(`Excluding job ${document.id} - already sent to customer.`);
                        continue;
                    }

                    // Count pending jobs
                    if (jobData.sentToCustomer === false || jobData.sentToCustomer == null) {
                        countPending++;
                    }

                    const servicesArray = Array.isArray(jobData.services)
                        ? jobData.services
                        : typeof jobData.services === 'string'
                        ? jobData.services.split(',').map(s => s.trim())
                        : [];

                    const imageUrls = await fetchCachedImageUrls(document.id, jobData.imageUrls || {});

                    jobsDataArray.push({
                        id: document.id,
                        ...jobData,
                        property: jobData.property || 'Unnamed Property',
                        services: servicesArray,
                        imageUrls,
                    });
                }

                setJobsData(jobsDataArray);
                setPendingCount(countPending);
            } catch (error) {
                console.error("Error fetching jobs:", error);
            } finally {
                setLoading(false);
            }
        };

        if (!authLoading) {
            fetchJobs();
        }
    }, [user, authLoading]);

    const fetchCachedImageUrls = async (jobId, currentImageUrls) => {
        const storage = getStorage();
        const freshImageUrls = { ...currentImageUrls };

        for (const [key, oldUrl] of Object.entries(currentImageUrls)) {
            if (imageCacheRef.current[jobId]?.[key]) {
                freshImageUrls[key] = imageCacheRef.current[jobId][key];
                continue;
            }

            if (typeof oldUrl === 'string' && oldUrl) {
                try {
                    const path = decodeURIComponent(
                        oldUrl.split('/o/')[1].split('?')[0].replace(/%2F/g, '/')
                    );
                    const imageRef = ref(storage, path);
                    const freshUrl = await getDownloadURL(imageRef);

                    if (!imageCacheRef.current[jobId]) {
                        imageCacheRef.current[jobId] = {};
                    }
                    imageCacheRef.current[jobId][key] = freshUrl;
                    freshImageUrls[key] = freshUrl;
                } catch (error) {
                    console.error(`Error fetching URL for ${key}:`, error);
                    freshImageUrls[key] = oldUrl;
                }
            } else {
                freshImageUrls[key] = oldUrl;
            }
        }

        return freshImageUrls;
    };

    const openImageModal = (url) => setModalImage(url);

    const closeImageModal = () => setModalImage(null);

    const handleSave = async (updatedJobData) => {
        try {
            const jobRef = doc(db, 'Services', updatedJobData.id);
            await updateDoc(jobRef, updatedJobData);

            const updatedImageUrls = await fetchCachedImageUrls(updatedJobData.id, updatedJobData.imageUrls);

            setJobsData(prevJobsData =>
                prevJobsData.map(jobData =>
                    jobData.id === updatedJobData.id
                        ? { ...updatedJobData, imageUrls: updatedImageUrls }
                        : jobData
                )
            );

            setIsEditing(false);
            setSelectedJobData(null);
        } catch (error) {
            console.error("Error saving updated job:", error);
        }
    };

    const handleDelete = async (jobId) => {
        if (!jobId) {
            console.error("No job ID provided");
            return;
        }

        if (window.confirm('Are you sure you want to delete this job? This action cannot be undone.')) {
            try {
                setLoading(true);
                const jobRef = doc(db, 'Services', jobId);
                await deleteDoc(jobRef);

                setJobsData(prevJobs => prevJobs.filter(job => job.id !== jobId));
            } catch (error) {
                console.error("Error deleting job:", error);
                alert('Failed to delete job: ' + error.message);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleEditClick = (jobData) => {
        setSelectedJobData(jobData);
        setIsEditing(true);
    };

    const handleEmailClick = (jobData) => {
        if (isValidJobData(jobData)) {
            setSelectedJobData(jobData);
            setIsEmailModalOpen(true);
        } else {
            alert("Unable to send email: Job data is incomplete or invalid.");
        }
    };

    const handleSearchChange = (event) => setSearchQuery(event.target.value.toLowerCase());

    const filteredJobsData = jobsData
        .filter(jobData => !jobData.sentToCustomer)
        .filter(jobData => {
            const searchTerms = searchQuery.split(' ').filter(term => term);
            return (
                searchTerms.length === 0 ||
                searchTerms.every(term =>
                    jobData.property.toLowerCase().includes(term) ||
                    jobData.services.some(service => service.toLowerCase().includes(term)) ||
                    (jobData.date && jobData.date.toLowerCase().includes(term))
                )
            );
        })
        .sort((a, b) => (a.createdAt?.toMillis() || 0) - (b.createdAt?.toMillis() || 0));

    if (authLoading) return <div>Loading authentication...</div>;
    if (!user) return <div>Please log in to view jobs</div>;
    if (loading) return <div>Loading jobs...</div>;

    return (
        <div className="job-list-container">
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search by property, service, or date..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="search-input"
                />
            </div>
            <div className="job-list-header">
                <h1>Services Needing Approval</h1>
                <p>Total Pending Services: <strong>{pendingCount}</strong></p>
                <button onClick={() => navigate('/manager-dashboard')} className="back-button">
                    ← Back to Dashboard
                </button>
            </div>
            <div className="job-grid">
                {filteredJobsData.map(jobData => (
                    <div key={jobData.id} className="job-card">
                        <div className="job-header">
                            <h3>{jobData.property}</h3>
                            <p>{jobData.supervisorName}</p>
                            <p className="date">{jobData.date}</p>
                        </div>
                        {jobData.imageUrls && Object.entries(jobData.imageUrls).length > 0 && (
                            <div className="image-grid">
                                {Object.entries(jobData.imageUrls).map(([key, url]) => (
                                    url && (
                                        <div key={key} className="image-container">
                                            <img
                                                src={url}
                                                alt={`${key} view`}
                                                onClick={() => openImageModal(url)}
                                            />
                                        </div>
                                    )
                                ))}
                            </div>
                        )}
                        {modalImage && (
                            <div className="modal-overlay" onClick={closeImageModal}>
                                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                                    <button className="close-modal" onClick={closeImageModal}>×</button>
                                    <img src={modalImage} alt="Enlarged view" />
                                </div>
                            </div>
                        )}
                        <div className="services-section">
                            <h4>Services:</h4>
                            <ul>
                                {jobData.services.map((service, index) => (
                                    <li key={index}>{service}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="notes-section">
                            <h4>Notes:</h4>
                            <p>{jobData.notes || 'No notes available'}</p>
                        </div>
                        <div className="button-container">
                            <button onClick={() => handleEmailClick(jobData)} className="email-button">
                                Send Email & Push to Quickbase
                            </button>
                            <button onClick={() => navigate(`/jobs/${jobData.id}`)} className="view-details-button">
                                View Details
                            </button>
                            <button
                                onClick={() => handleQuickBaseUpdate(jobData)}
                                className="push-to-quickbase-button"
                                disabled={quickBaseLoading}
                            >
                                {quickBaseLoading ? 'Updating...' : 'Send to QuickBase (Skip Email)'}
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleDelete(jobData.id);
                                }}
                                className="delete-button"
                                disabled={loading}
                            >
                                {loading ? 'Deleting...' : 'Delete Job'}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            {isEmailModalOpen && selectedJobData && (
                <div className="modal-overlay" onClick={() => setIsEmailModalOpen(false)}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <EmailSender
                            selectedJob={selectedJobData}
                            onClose={() => {
                                setIsEmailModalOpen(false);
                                setSelectedJobData(null);
                            }}
                        />
                    </div>
                </div>
            )}
            {isEditing && selectedJobData && (
                <div className="modal-overlay" onClick={() => setIsEditing(false)}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <EditJob
                            jobData={selectedJobData}
                            onSave={handleSave}
                            onClose={() => {
                                setIsEditing(false);
                                setSelectedJobData(null);
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default JobList;
