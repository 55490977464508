import React, { useState, useEffect, useRef, useContext } from "react";
import SignatureCanvas from "react-signature-canvas";
import { db } from '../Firebase Functions/firebase';
import { useTranslation } from 'react-i18next';
import { collection, getDocs, doc, updateDoc, addDoc, getDoc, setDoc } from "firebase/firestore";
import "./ChecklistForm.css";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../hooks/AuthProvider";


const ChecklistForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [mileage, setMileage] = useState(""); // State to hold fetched mileage



  // Helper function to get today's date in YYYY-MM-DD format
  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  // Helper function to get the current time in HH:MM format
  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };
  const [inspectionType, setInspectionType] = useState("pre-trip"); 
  const [formData, setFormData] = useState({
    date: getTodayDate(),
    time: getCurrentTime(), // Autopopulate with current time
    truckId: "",
    trailerId: "",
    odometer: "",
    checklist: {
      " Air Compressor": false,
      " Battery": false,
      " Body": false,
      " Brakes Parking": false,
      " Brakes Service": false,
      " Brake Tag": false,
      " Coupling Devices": false,
      " Defroster Heater": false,
      " Exhaust": false,
      " Engine Compartment": false,
      " Fluids": false,
      " Frame Assembly": false,
      " Front Axle": false,
      " Horn": false,
      " Insurance Papers": false,
      " Mirrors": false,
      " Registration": false,
      " Lights": false,
      " Reflectors": false,
      " Safety Equipment": false,
      " Suspension System": false,
      " Steering": false,
      " Tires": false,
      " Wheels Rims": false,
      " Windows": false,
      " Windshield Wipers": false,
      " Fuel Tank": false,
      " Other": false,
    },
    trailerChecklist: {
      " Brakes": false,
      " Coupling Devices": false,
      " Chains Binders": false,
      " Cargo Secured": false,
      " Landing Gear": false,
      " Lights": false,
      " Suspension System": false,
      " Tires": false,
      " Wheels Rims": false,
      " Other": false,
    },
    comments: "",
    defectsCorrected: false,
    defectsNotNeeded: false,
  });

  const [trucks, setTrucks] = useState([]);
  const signatureRef = useRef(null);
  const [filteredTrucks, setFilteredTrucks] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [assignedVehicles, setAssignedVehicles] = useState([]);

  useEffect(() => {
    if (inspectionType === "pre-trip" && formData.truckId) {
      fetchTruckMileage(formData.truckId); // Fetch mileage for pre-trip
    } else {
      setMileage(""); // Clear mileage for post-trip or no truck selected
    }
  }, [inspectionType, formData.truckId]); // Run effect when inspection type or truck ID changes
  

  useEffect(() => {
    const fetchTrucks = async () => {
      const truckCollection = collection(db, "Trucks");
      const truckSnapshot = await getDocs(truckCollection);
      setTrucks(truckSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };

    fetchTrucks();
  }, []);

  // Handle checklist changes
  const handleInputChange = async (e) => {
    const { name, value, type, checked } = e.target;
  
    if (type === "checkbox") {
      const [section, field] = name.split(".");
      const updatedChecklist = {
        ...formData[section],
        [field]: checked,
      };
  
      const updatedFormData = {
        ...formData,
        [section]: updatedChecklist,
      };
  
      setFormData(updatedFormData);
  
      // Update Firestore checklist for the truck
      if (formData.truckId) {
        try {
          const inspectionRef = doc(db, "vehicleInspections", formData.truckId);
          const inspectionDoc = await getDoc(inspectionRef);
  
          let updatedFirestoreChecklist = {};
          if (inspectionDoc.exists()) {
            const currentChecklist = inspectionDoc.data().checklist || {};
            updatedFirestoreChecklist = { ...currentChecklist };
  
            if (checked) {
              updatedFirestoreChecklist[field] = true;
            } else {
              delete updatedFirestoreChecklist[field];
            }
          } else {
            if (checked) {
              updatedFirestoreChecklist[field] = true;
            }
          }
  
          // If all items are unchecked, clear the checklist field in Firestore
          const hasDefects = Object.values(updatedFirestoreChecklist).some((value) => value);
  
          await setDoc(
            inspectionRef,
            {
              checklist: hasDefects ? updatedFirestoreChecklist : {}, // Clear checklist if no defects
              truckId: formData.truckId,
              date: formData.date,
              time: formData.time,
            },
            { merge: true } // Preserve other fields
          );
        } catch (error) {
          console.error("Error updating checklist in Firebase:", error);
        }
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  

  
  

  const handleSignatureClear = () => {
    signatureRef.current.clear();
  };

  const updateTruckMileage = async (truckId, newMileage) => {
    try {
      const truckRef = doc(db, "Trucks", truckId);
  
      // Update mileage in the Firestore document
      await updateDoc(truckRef, { mileage: newMileage });
      console.log(`Truck ${truckId} mileage updated to ${newMileage}`);
    } catch (error) {
      console.error("Error updating truck mileage:", error);
      alert("Failed to update truck mileage. Please try again.");
    }
  };

  const handleTruckIdChange = (e) => {
    const value = e.target.value;
    setFormData((prev) => ({ ...prev, truckId: value }));

    if (value.trim() === "") {
      setFilteredTrucks([]);
      setShowSuggestions(false);
    } else {
      const filtered = trucks.filter((truck) =>
        truck.vehicleName?.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredTrucks(filtered);
      setShowSuggestions(true);
    }
  };

  const handleTruckSelect = (truck) => {
    setFormData((prev) => ({ ...prev, truckId: truck.id }));
    setShowSuggestions(false);
  };

  const fetchTruckMileage = async (truckId) => {
    try {
      const truckRef = doc(db, "Trucks", truckId);
      const truckDoc = await getDoc(truckRef);
      if (truckDoc.exists()) {
        const currentMileage = truckDoc.data().mileage || 0;
        setMileage(currentMileage); // Set mileage from Firestore
      } else {
        console.error("Truck not found in Firestore.");
      }
    } catch (error) {
      console.error("Error fetching truck mileage:", error);
    }
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const signatureData =
      signatureRef.current && !signatureRef.current.isEmpty()
        ? signatureRef.current.getTrimmedCanvas().toDataURL("image/png")
        : null;
  
    if (!signatureData) {
      alert("Please provide your signature before submitting.");
      return; // Stops further execution
    }
  
    if (!formData.truckId) {
      alert("Please select a truck.");
      return; // Stops further execution
    }
  
    if (inspectionType === "post-trip" && (!formData.odometer || isNaN(formData.odometer))) {
      alert("Please enter a valid odometer reading for post-trip.");
      return; // Stops further execution
    }
  
    try {
      const sanitizedData = {
        date: formData.date || new Date().toISOString(),
        time: formData.time || new Date().toLocaleTimeString(),
        truckId: formData.truckId,
        trailerId: formData.trailerId || "",
        odometer: parseFloat(formData.odometer), // Ensure odometer is stored as a number
        comments: formData.comments || "",
        signature: signatureData,
        timestamp: new Date().toISOString(),
      };
  
      // Reference to the Firestore document for this truck's inspection
      const truckRef = doc(db, "Trucks", formData.truckId);
      const truckDoc = await getDoc(truckRef);
  
      // Validate the new mileage against the previous mileage
      if (truckDoc.exists()) {
        const previousMileage = truckDoc.data().mileage || 0;
        const newMileage = parseFloat(formData.odometer);
  
        if (newMileage < previousMileage) {
          alert(
            `Invalid mileage entry. The new mileage (${newMileage}) cannot be less than the previous mileage (${previousMileage}).`
          );
          return; // Stops further execution
        }
      }
  
      // Update mileage in the truck document
      await updateDoc(truckRef, { mileage: parseFloat(formData.odometer) });
  
      // Reference to the Firestore document for this truck's inspection
      const inspectionRef = doc(db, "vehicleInspections", formData.truckId);
      const inspectionDoc = await getDoc(inspectionRef);
  
      let existingChecklist = {};
      if (inspectionDoc.exists()) {
        existingChecklist = inspectionDoc.data().checklist || {};
      }
  
      // Merge the current formData.checklist with the existing checklist
      const updatedChecklist = {
        ...existingChecklist,
        ...formData.checklist,
      };
  
      // Remove unchecked items
      Object.keys(updatedChecklist).forEach((key) => {
        if (!formData.checklist[key]) {
          delete updatedChecklist[key];
        }
      });
  
      // Save the updated checklist to Firestore
      await setDoc(
        inspectionRef,
        {
          ...sanitizedData,
          checklist: updatedChecklist,
        },
        { merge: true }
      );
  
      alert("Checklist submitted successfully!");
      console.log("Saved Checklist Data:", sanitizedData);
  
      setFormData({
        date: getTodayDate(),
        time: getCurrentTime(),
        truckId: "",
        trailerId: "",
        odometer: "",
        checklist: Object.keys(formData.checklist).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {}),
        trailerChecklist: Object.keys(formData.trailerChecklist).reduce(
          (acc, key) => {
            acc[key] = false;
            return acc;
          },
          {}
        ),
        comments: "",
      });
  
      navigate("/supervisor-dashboard");
    } catch (error) {
      console.error("Error saving checklist:", error);
      alert("Failed to save checklist. Please try again.");
    }
  };

  const assignVehicleToUser = async () => {
    if (!selectedVehicle) {
      alert('Please select a vehicle.');
      return;
    }

    try {
      const vehicleRef = doc(db, 'Trucks', selectedVehicle.id);
      await updateDoc(vehicleRef, { assignedTo: currentUser.email });
      alert(`Vehicle ${selectedVehicle.vehicleName} assigned to you.`);
      setAssignedVehicles((prev) => [...prev, { ...selectedVehicle, assignedTo: currentUser.email }]);
      setSearchTerm('');
      setSelectedVehicle(null);
    } catch (error) {
      console.error('Error assigning vehicle:', error);
      alert('Failed to assign vehicle.');
    }
  };
  
  
  
  
  

  return (
    <form onSubmit={handleSubmit} className="checklist-form">
      <h1 className="form-title">{t('Vehicle Inspection Checklist')}</h1>

      <div className="form-group">
        <label>{t('Inspection Type')}</label>
        <div className="toggle-buttons">
          <label>
            <input
              type="radio"
              name="inspectionType"
              value="pre-trip"
              checked={inspectionType === "pre-trip"}
              onChange={() => setInspectionType("pre-trip")}
            />
            {t('Pre-Trip')}
          </label>
          <label>
            <input
              type="radio"
              name="inspectionType"
              value="post-trip"
              checked={inspectionType === "post-trip"}
              onChange={() => setInspectionType("post-trip")}
            />
            {t('Post-Trip')}
          </label>
        </div>
      </div>

      

      <div className="form-group">
        
        <label>{t('Date')}</label>
        <input
          type="date"
          name="date"
          value={formData.date}
          onChange={handleInputChange}
          required
          className="form-control"
        />
      </div>

      <div className="form-group">
        <label>{t('Time')}</label>
        <input
          type="time"
          name="time"
          value={formData.time}
          onChange={handleInputChange}
          required
          className="form-control"
        />
      </div>

      <div className="form-group">
        <label>{t("Truck ID")}</label>
        <div style={{ position: "relative" }}>
          <input
            type="text"
            name="truckId"
            value={formData.truckId}
            onChange={handleTruckIdChange}
            onFocus={() => setShowSuggestions(true)}
            onBlur={() => setTimeout(() => setShowSuggestions(false), 150)}
            placeholder="Type or select a truck"
            className="form-control"
          />
          {showSuggestions && filteredTrucks.length > 0 && (
            <ul
              style={{
                listStyle: "none",
                padding: 0,
                margin: "0.5rem 0 0",
                border: "1px solid #ccc",
                backgroundColor: "#fff",
                position: "absolute",
                zIndex: 10,
                maxHeight: "150px",
                overflowY: "auto",
                width: "100%",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              {filteredTrucks.map((truck) => (
                <li
                  key={truck.id}
                  onClick={() => handleTruckSelect(truck)}
                  style={{
                    padding: "0.5rem",
                    cursor: "pointer",
                  }}
                >
                  {truck.vehicleName || `Truck ${truck.id}`}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>


      <div className="form-group">
        <label>{t('Trailer ID')}</label>
        <input
          type="text"
          name="trailerId"
          value={formData.trailerId}
          onChange={handleInputChange}
          required
          className="form-control"
        />
      </div>

      <div className="form-group">
  <label>{t('Odometer Reading')}</label>
  <input
    type="number"
    name="odometer"
    value={mileage || formData.odometer} // Use mileage if available, else formData.odometer
    onChange={handleInputChange}
    readOnly={inspectionType === "pre-trip"} // Make field read-only for pre-trip
    required
    className="form-control"
  />
</div>


      <h2 className="section-title">{t('Truck Inspection')}</h2>
      <div className="checklist-section">
        {Object.keys(formData.checklist).map((key) => (
          <div key={key} className="checkbox-item">
            <label>
              <input
                type="checkbox"
                name={`checklist.${key}`}
                checked={formData.checklist[key]}
                onChange={handleInputChange}
              />
              {t(key)}
            </label>
          </div>
        ))}
      </div>

      <div className="form-group">
  <label>{t('Driver Signature')}</label>
  <div className="signature-container">
    <SignatureCanvas
      ref={signatureRef}
      penColor="black"
      canvasProps={{
        className: "signature-canvas",
        style: { border: "1px dashed #ccc", width: "100%", height: "150px" },
      }}
    />
    <p className="signature-hint">{t('Sign within the box')}</p>
    <button
      type="button"
      onClick={handleSignatureClear}
      className="clear-button"
    >
      {t('Clear Signature')}
    </button>
  </div>
</div>
     

      <button type="submit" className="submit-button">{t('Submit Checklist')}</button>
    </form>
  );
};

export default ChecklistForm;
